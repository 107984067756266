<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component :label="$t('emails.receiver')" :value="callCenterEmail.receiver"/>
    <list-group-item-component :label="$t('fields.firstname')" :value="receiverFullName"/>
    <list-group-item-component :label="$t('fields.date')">
      <template slot="value">
        <label-theme-component>{{ callCenterEmail.date | date }} {{ callCenterEmail.time }}</label-theme-component>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('callcenter.subject')" :value="callCenterEmail.subject"/>
    <list-group-item-component :label="$t('common.cc')" :value="ccList"/>
    <li class="list-group-item p-2">
      <div class="text-uppercase small">{{ $t('common.message') }}</div>
      <div v-html="callCenterEmail.message" class="max-height-300"></div>
    </li>
  </ul>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import CallCenterEmail from "@/models/callCenterEmail";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "CallCenterEmailDetails",
  components: {LabelThemeComponent, ListGroupItemComponent},
  props: {
    callCenterEmail: {
      type: Object,
      default: () => _.cloneDeep(CallCenterEmail)
    }
  },
  computed: {
    receiverFullName() {
      const registry = this.callCenterEmail?.lead?.registry || {};

      return registry?.surname + ' ' + registry?.name;
    },
    ccList() {
      return !this.$_.isEmpty(this.callCenterEmail.cc)
        ? this.$_.join(this.callCenterEmail.cc, ', ')
        : '';
    }
  }
}
</script>

<style scoped>

</style>
