<template>
  <div class="row">
    <div class="col-md-12">
      <call-center-email-datatable/>
    </div>
  </div>
</template>

<script>
import CallCenterEmailDatatable from "@/pages/Email/datatables/CallCenterEmailDatatable";
export default {
  name: "IndexCallCenterEmailPage",
  components: {CallCenterEmailDatatable}
}
</script>

<style scoped>

</style>
