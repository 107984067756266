<template>
  <div>
    <octo-table
      show-filters
      :action="endpoints.DATATABLES.callCenterEmails"
      :fields="fields"
      :filters="filters"
      :name="name"
      :order-by-items="['created_at']"
      @onResetFilters="filters = {}"
    >
      <template v-slot:receiver="data">
        <div class="text-truncate">
          {{ data.row | optional('lead.registry.surname') }}
          {{ data.row | optional('lead.registry.name') }}
        </div>
        <div class="small">
          {{ data.row | optional('receiver') }}
        </div>
      </template>

      <template v-slot:subject="data">
        <div class="text-truncate small">{{ data.row.subject }}</div>
      </template>

      <template v-slot:date="data">
        {{ data.row.date | date }} {{ data.row.time }}
      </template>

      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <el-tooltip :content="$t('common.info')" :open-delay="300" placement="top">
            <base-button class="mx-1" size="sm" icon link @click="openModal(data.row)">
              <octo-icon icon="magnifying-glass"/>
            </base-button>
          </el-tooltip>
        </div>
      </template>
    </octo-table>
    <modal centered modalClasses="modal-lg" :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">{{ $t('common.details') }}</h5>
      <call-center-email-details :call-center-email="currentCallCenterEmail" :key="modalKey"/>
    </modal>
  </div>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {endpoints} from "@/routes/endpoints";
import {Option, Select} from "element-ui";
import Modal from "@/components/Modal";
import CallCenterEmail from "@/models/callCenterEmail";
import CallCenterEmailDetails from "@/pages/Email/components/CallCenterEmailDetails";
import {mapGetters} from "vuex";

export default {
  name: "CallCenterEmailDatatable",
  components: {
    CallCenterEmailDetails,
    Modal,
    LabelThemeComponent,
    OctoIcon,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      endpoints: endpoints,
      name: "CallCenterEmailDatatable",
      filters: {},
      showModal: false,
      modalKey: 0,
      currentCallCenterEmail: _.cloneDeep(CallCenterEmail),
      fields: [
        {prop: 'receiver', label: 'receiver', slot: true, width: 300},
        {prop: 'subject', label: 'subject', slot: true, minWidth: 150},
        {prop: 'date', label: 'date', align: 'center', slot: true, width: 200},
        {prop: 'actions', label: 'actions', slot: true, align: 'center', fixed: 'right'},
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
    }),
  },
  methods: {
    openModal(callCenterEmail) {
      this.currentCallCenterEmail = this.$_.cloneDeep(callCenterEmail);
      this.modalKey++
      this.showModal = true;
    },
  }
}
</script>

<style scoped>

</style>
